import React, { useReducer } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Download from './pages/Download'
import Home from './pages/Home';
import Template from './pages/Template';
import RootReducer from './rootReducer';
import Contact from './pages/Contact'
import Help from './pages/Help'
import GetTrail from './pages/GetTrail'
import About from './pages/About'
import Term from './pages/Term'
import DownloadOk from './pages/DownloadOk'


export const RootDispatch = React.createContext(null);

function App() {
  const [state, dispatch] = useReducer(RootReducer)
  console.log('get env',process.env)
  return (
    <RootDispatch.Provider value={{
      dispatch,
      state
    }}>
      <Router>
        <Switch >
          <Route exact path="/download.html" component={Download} />
          <Route exact path="/downloadOk.html" component={DownloadOk} />
          <Route exact path="/templates.html" component={Template} />
          <Route exact path="/contact.html" component={Contact} />
          <Route exact path="/help.html" component={Help} />
          <Route exact path="/getTrail.html" component={GetTrail} />


          <Route exact path="/about.html" component={About} />
          <Route exact path="/term.html" component={Term} />
          <Route exact path="/" component={Home} />
          <Route exact path="/index.html" component={Home} />
        </Switch>

      </Router>
    </RootDispatch.Provider>

  );
}

export default App;
