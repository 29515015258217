import React, { useEffect, useState } from 'react';
import BasePage from '../../components/BasePage';
import {getQueryByLocation} from '../../util';  
import Link from '../../images/link.png'
import copy from "copy-to-clipboard";

const StartDownload = (props) => {
  const [toast,setToast]=useState(false)

  const query = getQueryByLocation(props.location) 
  const url = query.url || ''
  useEffect(()=>{
    if(url) {
      window.open(query.url)
    }
  }, [url])
  return (
    <BasePage currentPath={props.match.path}  >
      <div className="h-inner">
      {toast&&<div className="toast">复制成功</div>}

        <div className='f26 ' style={{paddingBottom:10,borderBottom:'1px solid #f2f2f2',marginTop:60}}>温馨提示</div>
        <div className="mt30 lt-spc2 ">页面将自动触发下载，如未触发，您可以<a href={url} style={{textDecoration:'underline'}}>点此下载</a>或直接复制下方链接到地址栏中下载。</div>
        <div style={{marginTop:20}}>
          <div className="flex alc">
          <div className='flex alc ' style={{height:50,width:500,borderColor:'#ebebeb',color:'#848181',border:'1px solid #f2f2f2',paddingLeft:10}}> https:{url}</div>
          <div className='flex alc jc_c' style={{height:52,background:'#ebebeb',color:'#010523',width:130}} onClick={()=>{
            copy('https:'+url)
            setToast(true)
setTimeout(() => {
setToast(false)
}, 2000);
          }}>
            <img src={Link} style={{width:20}} mode="widthFix" className='mr10'></img>
            <span>复制链接</span>
          </div>
          </div>
          {/* <button className='btn btn-green' style={{marginLeft:10}} onClick={()=>{

          }}>复制</button> */}
          <div className='mt30'>
          <div><span className='cr'>*</span>请在安装D9软件时，<span className='cr'>勿将C盘</span>作为安装路径，您可以选择其他的磁盘位置作为安装路径</div>
          <div className='mt20'><span className='cr'>*</span>推荐优先使用i7/i9芯片、16G内存以上配置电脑运行。</div>
          </div>
        </div>
      </div>
    </BasePage>

  )
}


export default StartDownload;