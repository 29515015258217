import React, { useEffect, useState } from 'react';
import BasePage from '../../components/BasePage';

import { getClientUrl } from '../../service';
import './01.png';
import './windows.png';
import './windows-hover.png';
import './style.css';


const Download = (props) => {
  const [info, setInfo] = useState(null)
  useEffect(() => {
    getClientUrl().then((doc) => {
      setInfo(doc)
    }).catch((err) => {
      console.log('get url error', err)
    })

  }, [])


  return (
    <BasePage currentPath={props.match.path} >
      {info && <div>
        <div className="a-download clear">
        <div className="down-ietm windows">
          <div className="down-ietm-inner">
            <div className="logo-area">
              <div className="itemlogo"></div>
            </div>
            <div className="info-area">
              <div className="d-item-title">Windows版</div>
              <div className="d-item-desc">版本{info.version} <span className="update-time">{info.releaseDate}</span></div>
              <div className="down-btn"><a href={`/downloadOk.html?url=${encodeURIComponent(info.url.replace('http:',''))}`} >下载</a></div>
            </div>
          </div>
        </div>

        <div className="down-ietm mac">
          <div className="down-ietm-inner">
            <div className="logo-area">
              <div className="itemlogo"  ></div>
            </div>
            <div className="info-area">
              <div className="d-item-title">Mac版</div>
              <div className="d-item-desc">版本{info.version} <span className="update-time">{info.releaseDate}</span></div>
              <div className="down-btn"><a href={`/downloadOk.html?url=${encodeURIComponent(info.macUrl.replace('http:',''))}`} >下载</a></div>
            </div>
          </div>
        </div>
      </div>
      <div className="center mtxs" >
        <div className="">
          若遇到无法下载，可点击<a target='_blank' href="https://pan.baidu.com/s/1nsiPk2-ZfF6NQ2IKQeweBg?pwd=kjf0" style={{textDecoration:'underline',color:'#222'}} alt="百度网盘下载链接">百度网盘</a>下载（提取码:kjf0）
        </div>
      </div>
      <div className='center' style={{marginTop:'10px'}}>
          <div><span className='cr'>*</span>请在安装D9软件时，<span className='cr'>勿将C盘</span>作为安装路径，您可以选择其他的磁盘位置作为安装路径</div>
          <div className='maxs'><span className='cr'>*</span>推荐优先使用i7/i9芯片、16G内存以上配置电脑运行。</div>
          </div>
        </div>}
    </BasePage>

  )
}


export default Download;