import React, { useEffect, useState } from 'react';
import BasePage from '../../components/BasePage';
import QrcodeImg from '../Contact/qrcode.jpg';
import { IsPC } from '../../util';

const ContactUs = (props) => {
  const isPC = IsPC()
const imgW=isPC ? 800 : window.innerWidth - 20
  return (
    <BasePage currentPath={props.match.path} >
      <div style={{ marginTop: isPC ? '' : '150px', width: isPC ? '' : window.innerWidth }}>
        <div className="center" style={{width: isPC ? 800 : window.innerWidth - 20,position:'relative',left:'50%',top:'50%',transform:'translateX(-50%)'}}>
          <div style={{position:'absolute', top:30,left:imgW/8.5,fontSize:imgW/24,color:'#ffffff'}}>扫码联系客服领取试用</div>
          <img src={QrcodeImg}  style={{ width: imgW, border: '1px solid #90caf9' }} className='centerd'/>
        </div>

      </div>
    </BasePage>

  )
}


export default ContactUs;